import styled from "styled-components";

import { CONSTANTS } from "../../../config/constants";
import { SelectTransactionDropdownStyledProps as Props } from "./SelectTransactionDropdown.types";
import { DropdownOverlayStyledProps as OverlayProps } from "./SelectTransactionDropdown.types";

const { tablet } = CONSTANTS.BREAKPOINTS;

const SelectTransactionDropdownStyled = styled.div<Props>`
  .SelectTransactionDropdown {
    &__button {
      border-radius: 10rem;
      padding: 1.2rem 2rem;
    }

    &__title {
      color: var(--palette-white);
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
    }
  }
`;

export const DropdownOverlayStyled = styled.div<OverlayProps>`
  @media (max-width: ${tablet}px) {
    width: ${props => props.width + "px"};
  }

  .DropdownOverlay {
    &__description {
      display: flex;
      padding: 1.6rem 2rem;
      gap: 1rem;

      &__title {
        width: 100%;
        color: var(--palette-white);
        white-space: nowrap;
      }
    }

    &__options {
      background-color: var(--palette-white);
      border-top-right-radius: 2.8rem;
      border-top-left-radius: 2.8rem;
      padding: 2.4rem;

      @media (max-width: ${tablet}px) {
        padding-bottom: 8rem;
      }
    }

    &__option {
      cursor: pointer;
      display: flex;
      gap: 2rem;

      &:last-child {
        margin-top: 3.4rem;
      }

      h3 {
        color: var(--palette-black);
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 0.4rem;
      }

      p {
        color: var(--palette-gray-s0-l50);
      }

      &__arrow {
        &--top {
          background-color: var(--palette-gray-s0-l95);
          padding: 0.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10rem;
          width: 3.7rem;
          height: 3.7rem;
          transform: rotate(180deg);
        }

        &--bottom {
          background-color: var(--palette-gray-s0-l95);
          padding: 0.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10rem;
          width: 3.7rem;
          height: 3.7rem;
          transform: rotate(0deg);
        }
      }
    }
  }
`;

export default SelectTransactionDropdownStyled;
