import { TabsMenu as TabsMenuUI } from "artisn-ui-react";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./InvestTabsMenuItem.styles";
import { InvestTabsMenuItemProps as Props } from "./InvestTabsMenuItem.types";
import useI18n from "hooks/useI18n";

import InvestSVG from "../../../../../public/assets/images/anefi/money-sack.svg";

const { Item } = TabsMenuUI;

const InvestTabsMenuItem: React.FC<Props> = props => {
  const { className, hideTitle, active } = props;
  const { push } = useRouter();
  const t = useI18n();

  const onClickHandler = async () => {
    await push("/products");
  };

  return (
    <Styles className={`InvestTabsMenuItem ${className}`} active={active}>
      <Item
        icon={InvestSVG}
        title={hideTitle ? undefined : t.navigation.invest}
        onClick={onClickHandler}
      />
    </Styles>
  );
};

InvestTabsMenuItem.defaultProps = {
  className: ""
};

export default InvestTabsMenuItem;
