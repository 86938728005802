import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";

import Button from "../Button/Button";
import Logo from "../Logo/Logo";
import SelectTransactionDropdown from "../SelectTransactionDropdown/SelectTransactionDropdown";
import BalanceTabsMenuItem from "../navigation/BalanceTabsMenuItem/BalanceTabsMenuItem";
import HomeTabsMenuItem from "../navigation/HomeTabsMenuItem/HomeTabsMenuItem";
import InvestTabsMenuItem from "../navigation/InvestTabsMenuItem/InvestTabsMenuItem";
import UserTabsMenuItem from "../navigation/UserTabsMenuItem/UserTabsMenuItem";
import Styles from "./Navbar.styles";
import { NavbarProps as Props } from "./Navbar.types";
import useSignOut from "hooks/useSignOut";
import useAuth from "contexts/auth/auth.context.hooks";

import LogoNoTextSVG from "../../../../public/assets/images/anefi/logo-no-text.svg";
import SignOutSVG from "../../../../public/assets/images/anefi/sign-out.svg";

const Navbar: React.FC<Props> = props => {
  const { className } = props;
  const [active, setActive] = useState(-1);
  const { pathname, push } = useRouter();
  const { isAnonymous } = useAuth();
  const signOut = useSignOut();

  const onClick = () => {
    push("/");
  };

  useEffect(() => {
    if (pathname === "/") {
      setActive(0);
    } else if (pathname.startsWith("/products")) {
      setActive(1);
    } else if (pathname.startsWith("/balance")) {
      setActive(2);
    } else if (pathname.startsWith("/profile")) {
      setActive(3);
    }
  }, [pathname]);

  return (
    <Styles className={`Navbar ${className}`} id="navbar">
      <main className="Navbar__content">
        <div className="Navbar__left">
          <div className="Navbar__left__logo " onClick={onClick}>
            <LogoNoTextSVG className="Navbar__left__logo--noText" />
            <Logo className="Navbar__left__logo--text" />
          </div>
          <div className="Navbar__left__tabs">
            <HomeTabsMenuItem
              className="Navbar__left__tab"
              active={active === 0}
            />
            <InvestTabsMenuItem
              className="Navbar__left__tab"
              active={active === 1}
            />
            <BalanceTabsMenuItem
              className="Navbar__left__tab"
              active={active === 2}
            />
            <UserTabsMenuItem
              className="Navbar__left__tab"
              active={active === 3}
            />
          </div>
          <div className="Navbar__left__dropdown">
            <SelectTransactionDropdown />
          </div>
        </div>
        <div className="Navbar__right">
          <div className="Navbar__right__signOut">
            <Button
              type="BORDER"
              className="Navbar__right__button"
              onClick={
                isAnonymous ? () => push("/login-social-media") : signOut
              }
              isLoading={false}
            >
              <SignOutSVG />
              {isAnonymous ? "Ingresar" : "Cerrar Sesión"}
            </Button>
          </div>
        </div>
      </main>
    </Styles>
  );
};

Navbar.defaultProps = {
  className: ""
};

export default Navbar;
