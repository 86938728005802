import styled from "styled-components";

import { CONSTANTS } from "../../../../config/constants";
import { UserTabsMenuItemStyledProps as Props } from "./UserTabsMenuItem.types";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const UserTabsMenuItemStyled = styled.div<Props>`
  min-width: 4.4rem;

  &::after {
    position: absolute;
    display: block;
    bottom: 0.1rem;
    content: "";
    width: 4.2rem;
    height: 0.4rem;
    background-color: ${props =>
      props.active ? "var(--palette-primary)" : "var(--palette-white)"};
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;

    @media (max-width: ${tablet}px) {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;
  }

  & path {
    fill: ${props =>
      props.active ? "var(--palette-primary)" : "var(--palette-primary-light)"};
  }

  && .TabsMenuItem__title {
    color: ${props =>
      props.active ? "var(--palette-primary)" : "var(--palette-primary-light)"};
  }

  .UserTabsMenuItem {
  }
`;

export default UserTabsMenuItemStyled;
