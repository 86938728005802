import styled from "styled-components";

import { CONSTANTS } from "../../../config/constants";
import { NavbarStyledProps as Props } from "./Navbar.types";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const NavbarStyled = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  background-color: var(--palette-white);
  height: var(--sizes-navbar-height);

  .Navbar {
    &__content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 8rem;
      border-bottom: 0.1rem solid var(--palette-gray-s0-l90);

      @media (max-width: ${tablet + 396}px) {
        padding: 0 2rem;
      }
    }

    &__left {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6rem;
      margin-right: 6rem;

      @media (max-width: ${tablet + 396}px) {
        gap: 2.2rem;
        margin-right: 2rem;
      }

      &__tabs {
        display: flex;
        gap: 3.2rem;

        @media (max-width: ${tablet + 396}px) {
          gap: 2.8rem;
        }

        @media (max-width: ${tablet}px) {
          display: none;
        }
      }

      &__dropdown {
        position: relative;

        @media (max-width: ${tablet}px) {
          display: none;
        }
      }

      &__logo {
        display: flex;
        align-items: center;
        cursor: pointer;

        &--text {
          @media (max-width: ${tablet + 122}px) {
            display: none;
          }
        }

        &--noText {
          display: none;

          @media (max-width: ${tablet + 122}px) {
            display: unset;
          }
        }
      }
    }

    &__right {
      &__signOut {
        padding-left: 6rem;
        border-left: 0.1rem solid var(--palette-gray-s0-l50);

        @media (max-width: ${tablet + 224}px) {
          border-left: none;
          padding-left: 0;
        }
      }

      &__button {
        gap: 0.6rem;
        white-space: nowrap;

        &:hover {
          svg path {
            fill: var(--palette-white);
          }
        }
      }
    }
  }
`;

export default NavbarStyled;
