import { TabsMenu as TabsMenuUI } from "artisn-ui-react";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./BalanceTabsMenuItem.styles";
import { BalanceTabsMenuItemProps as Props } from "./BalanceTabsMenuItem.types";
import useI18n from "hooks/useI18n";

import WalletNavbarSVG from "../../../../../public/assets/images/anefi/wallet-navbar.svg";

const { Item } = TabsMenuUI;

const BalanceTabsMenuItem: React.FC<Props> = props => {
  const { className, hideTitle, active } = props;
  const { push } = useRouter();
  const t = useI18n();

  const onClickHandler = async () => {
    await push("/balance");
  };
  return (
    <Styles className={`BalanceTabsMenuItem ${className}`} active={active}>
      <Item
        icon={WalletNavbarSVG}
        title={hideTitle ? undefined : t.navigation.balance}
        onClick={onClickHandler}
      />
    </Styles>
  );
};

BalanceTabsMenuItem.defaultProps = {
  className: ""
};

export default BalanceTabsMenuItem;
