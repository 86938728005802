import { Button, Dropdown } from "artisn-ui-react";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./SelectTransactionDropdown.styles";
import { DropdownOverlayStyled } from "./SelectTransactionDropdown.styles";
import { SelectTransactionDropdownProps as Props } from "./SelectTransactionDropdown.types";
import { CONSTANTS } from "config/constants";
import useWindowSize from "hooks/useWindowSize";

import ArrowBottomSVG from "../../../../public/assets/images/anefi/arrow-bottom.svg";
import ArrowSkinnySVG from "../../../../public/assets/images/anefi/arrow-skinny.svg";
import ArrowsSVG from "../../../../public/assets/images/anefi/arrows.svg";

const { BREAKPOINTS, PRINCIPAL_PRODUCT_ID } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const SelectTransactionDropdown: React.FC<Props> = props => {
  const { className, investRoute = `/invest/${PRINCIPAL_PRODUCT_ID}` } = props;
  const { payOffRoute = "/payOff" } = props;
  const { availWidth, width } = useWindowSize();
  const { push } = useRouter();
  const isMobile = width <= tablet;

  const overlayNode = (
    <DropdownOverlayStyled className="DropdownOverlay" width={availWidth}>
      <div className="DropdownOverlay__description">
        <div className="DropdownOverlay__description__arrow">
          <ArrowsSVG />
        </div>
        <div className="DropdownOverlay__description__title">
          Con tu saldo podrás invertir y ahorrar
        </div>
      </div>
      <div className="DropdownOverlay__options">
        <div
          className="DropdownOverlay__option"
          onClick={() => push(investRoute)}
        >
          <div className="DropdownOverlay__option__arrow--top">
            <ArrowSkinnySVG />
          </div>
          <div>
            <h3>Aportar</h3>
            <p>Ingresa dinero en tu billetera</p>
          </div>
        </div>
        <div
          className="DropdownOverlay__option"
          onClick={() => push(payOffRoute)}
        >
          <div className="DropdownOverlay__option__arrow--bottom">
            <ArrowSkinnySVG />
          </div>
          <div>
            <h3>Retirar</h3>
            <p>Retira tu dinero de la billetera</p>
          </div>
        </div>
      </div>
    </DropdownOverlayStyled>
  );

  const buttonNode = (
    <Button className="SelectTransactionDropdown__button">
      <ArrowsSVG />
      <p className="SelectTransactionDropdown__title">Transacción</p>
      <ArrowBottomSVG className="SelectTransactionDropdown__button__arrow" />
    </Button>
  );

  return (
    <Styles className={`SelectTransactionDropdown ${className}`}>
      <Dropdown
        className="SelectTransactionDropdown__dropdown"
        overlay={overlayNode}
        target={["click"]}
        position={isMobile ? "right" : "left"}
        isFixed
      >
        {buttonNode}
      </Dropdown>
    </Styles>
  );
};

SelectTransactionDropdown.defaultProps = {
  className: ""
};

export default SelectTransactionDropdown;
